<template>
  <div class="miniBooth">
    <div class="tc miniTitle">
      {{ miniInfo.name_en | priorFormat(miniInfo.name_zh, LOCALE) }}
    </div>
    <div class="contentArea clearfix">
      <div class="left fl">
        <checkFarChannel :info="miniInfo"></checkFarChannel>
      </div>
      <div class="right fr">
        <div class="tc">
          <el-image
            :src="logo"
            class="image cursor"
            @click="$router.push('/')"
          ></el-image>
        </div>
        <div class="subtitle">—— {{ $t("innovationCooperation") }} ——</div>
        <div class="loginContent" :class="LOCALE == 'en' ? 'enTabs' : 'zhTabs'">
          <div class="otherLogin">
            <div class="otherTips">
              <div class="line"></div>
              <div class="txtTips">{{ $t("otherAccont") }}</div>
            </div>
            <div class="iconList tc">
              <img
                @click="gotoWxlogin"
                class="wxImg"
                :src="wxImg"
                :title="$t('wechatLogin')"
              />
              <img @click="gotoAiLa" :src="ailaImg" :title="$t('ailalogin')" />
              <img
                @click="gotoJinCheng"
                :src="jinImg"
                :title="$t('jctranLogin')"
              />
            </div>
          </div>
          <div class="emailSign" v-if="IS_EN">
            <sign
              @successChange="loginSuccess"
              :isInfo="true"
              @getLoading="btnLoadingChange"
            >
              <el-button
                slot="signBtn"
                type="primary"
                class="loginBut"
                :loading="emailLoading"
                >洽谈登录</el-button
              ></sign
            >
          </div>
          <el-tabs v-model="activeName" class="tabs" v-else>
            <el-tab-pane :label="$t('phoneLogin')" name="first">
              <div class="emailSign">
                <sign
                  :signType="'phone'"
                  :isInfo="true"
                  @successChange="loginSuccess"
                  @getLoading="btnLoadingChange"
                >
                  <el-button
                    slot="signBtn"
                    type="primary"
                    class="loginBut"
                    :loading="btnLoading"
                    >洽谈登录</el-button
                  >
                </sign>
              </div>
            </el-tab-pane>
            <el-tab-pane :label="$t('emailLogin')" name="second">
              <div class="emailSign">
                <sign
                  @successChange="loginSuccess"
                  @getLoading="btnLoadingChange"
                  :isInfo="true"
                >
                  <el-button
                    slot="signBtn"
                    type="primary"
                    class="loginBut"
                    :loading="emailLoading"
                    >洽谈登录</el-button
                  ></sign
                >
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { handleToVideoChatPage } from "~/baseUtils";
import checkFarChannel from "~/baseComponents/checkFarChannel";
import sign from "~/baseComponents/baseSign/sign";
import { getAilaDomainName } from "~/baseUtils";
export default {
  components: {
    checkFarChannel,
    sign,
  },
  methods() {
    const url = getAilaDomainName(this.LOCALE);
    const urlPath = `${url}/users/sign/otherLogin`;
    this.$store.commit("SET_ISAPPLY_SHOW", true);
  },
  data() {
    return {
      activeName: "first",
      btnLoading: false,
      emailLoading: false,
      miniInfo: {},
      miniId: "",
      logo:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/logo1.png",
      jinImg:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/jinImg.png",
      ailaImg:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/logo/logo_b.png",
      wxImg:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/wx.png",
      expires: 7,
    };
  },
  mounted() {
    console.log(window.location.origin);
    this.getList();
  },
  methods: {
    async getList() {
      try {
        let miniId = this.$route.query.id;
        this.miniId = miniId;
        let res = await this.$store.dispatch("booth/getMiniBoothInfo", {
          booth_id: miniId,
        });
        if (res.success) {
          this.$nextTick((e) => {
            this.miniInfo = res.data;
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    gotoWxlogin() {
      let chatVal = {
        channel_id: this.miniInfo.live_id,
        chat_type: "miniBooth",
        back_url: `${window.location.origin}/miniBooth?id=${this.miniId}`,
        user_id: this.USER_INFO.id,
      };
      let _chatVal = this._encode(chatVal);
      let chatUrl = window.btoa(
        `${window.location.origin}/chatLogin?chatVal=${_chatVal}`
      );
      const redirect_uri = encodeURI(chatUrl);
      window.location.href = `https://wx.ailaworld.com?appIdType=EXP&redirect_url=${redirect_uri}`;
    },
    gotoJinCheng() {
      let chatVal = {
        channel_id: this.miniInfo.live_id,
        chat_type: "miniBooth",
        back_url: `${window.location.origin}/miniBooth?id=${this.miniId}`,
        user_id: this.USER_INFO.id,
      };
      let _chatVal = this._encode(chatVal);
      let chatUrl = window.btoa(
        `${window.location.origin}/chatLogin?chatVal=${_chatVal}`
      );
      const redirect_uri = encodeURI(chatUrl);
      //   const redirect_uri = encodeURI(
      //     `${window.location.origin}/wxLogin&isChat=true`
      //   );
      //   window.location.href = `https://expologin.ailaworld.com?remarks=${redirect_uri}`;
      window.location.href = `https://expologin.ailaworld.com?remarks=${redirect_uri}`;
    },
    gotoAiLa() {
      let chatVal = {
        channel_id: this.miniInfo.live_id,
        chat_type: "miniBooth",
        back_url: `${window.location.origin}/miniBooth?id=${this.miniId}`,
        user_id: this.USER_INFO.id,
      };
      let _chatVal = this._encode(chatVal);
      let chatUrl = window.btoa(
        `${window.location.origin}/chatLogin?chatVal=${_chatVal}`
      );
      const redirect_uri = encodeURI(chatUrl);
      const url = getAilaDomainName(this.LOCALE);
      const urlPath = `${url}/users/sign/otherLogin`;
      window.location.href = `https://expologin.ailaworld.com?aila_path=${urlPath}&type=AiLa&remarks=${redirect_uri}`;
    },
    btnLoadingChange(val) {
      if (this.activeName == "first") {
        this.btnLoading = val;
      } else {
        this.emailLoading = val;
        s;
      }   
    },
    async loginSuccess(val) {
      handleToVideoChatPage(
        this.miniInfo.live_id,
        val.user_id,
        "miniBooth",
        true
      );
    },
    toRegister() {
      this.$router.push("/signUp");
    },
  },
};
</script>
<style lang="less" scoped>
.miniBooth {
  background: #f6f7f9;
  .miniTitle {
    font-size: 24px;
    padding: 100px 0 30px 0;
  }
  height: calc(100vh);
  .contentArea {
    width: 1200px;
    height: 544px;
    background: #ffffff;
    margin: 0 auto;
    border-radius: 10px;
    padding: 24px;
    .left {
      width: 680px;
      height: 490px;
      border-radius: 10px;
    }
    .right {
      width:440px;
      padding: 16px 24px;
      .image {
        width: 200px;
      }
      .subtitle {
        width: 100%;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        min-height: 30px;
        margin: 30px 0;
        line-height: 25px;
      }
      .loginContent {
        // margin-top: 30px;
        .loginBut {
          width: 390px;
          display: block;
          height: 40px;
          margin: 24px auto 12px;
          border: 0 none;
          font-size: 16px;
        }
      }
      .emailSign {
        margin-top: 10px;
      }
      .forgetPassword {
        a {
          color: #999999;
        }
      }
      .otherLogin {
        margin-top: 30px;
        padding-top: 5px;
      }
      .otherTips {
        color: #999;
        position: relative;
        .line {
          width: 100%;
          height: 1px;
          background: #ececec;
        }
        .txtTips {
          background: #ffffff;
          padding: 0 20px;
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
          top: -10px;
        }
      }
      .iconList {
        margin-top: 20px;
        .wxImg {
          width: 35px;
          height: auto;
        }
        img {
          height: 20px;
          margin: 0 8px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>